.laws {
  padding: 80px;
  background: linear-gradient(#ffffff, #dae6fc);

  h2 {
    color: #225e91;
    font-weight: 600;
  }

  .laws_wrapper {
    margin-left: 80px;
  }

  .shadow {
    // width: 30%;
    // background-color: white;
    box-shadow: 0 0 25px #e0e0e0;
    // padding: 35px;
    // margin-right: 0px;

    h5 {
      font-weight: 700;
      height: 50px;
    }

    a {
      color: black;
      text-decoration: none !important;
    }

    p {
      word-wrap: break-word;
      height: 70px;
    }
  }
}

.url_created {
  small {
    color: #225e91;
    opacity: 0.5;
  }
}

@media only screen and (max-width: 1024px) {
  .aboutUsLaw {
    ul {
      padding: 0;
    }
  }

  .laws {
    padding: 20px;

    .shadow {
      p {
        max-height: 120px;
      }
    }
  }

  // .laws {
  //   margin-bottom: 100px;
  //   width: 100%;
  //   h3 {
  //     color: #0c6abc;
  //   }
  //   .laws_wrapper {
  //     margin-left: 1px;
  //   }

  //   .laws_layout {
  //     width: 100%;
  //     box-shadow: 0 0 25px #e0e0e0;
  //     padding: 20px;
  //     margin: 0 auto;
  //     margin-bottom: 15px;
  //     h5 {
  //       font-weight: 700;
  //       margin-bottom: 26px;
  //     }
  //   }
  // }
}