@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;1,300&display=swap");

.carousel {
  background-color: #225e91;

  .slide {
    .slider-image {
      width: 50%;
      height: 223px;
      // object-fit: contain;
    }

    .svg {
      z-index: 2;
      height: 99%;
      top: 1%;

      img {
        max-width: 62% !important;
        top: -8%;
      }
    }

    .slider-logo {
      z-index: 3;
      position: absolute;
      max-width: 5% !important;
      top: 16%;
    }

    .slider-text {
      font-family: "Work Sans", sans-serif;
      position: absolute;
      top: 34%;
      width: 42%;
      z-index: 3;

      .Logo_app_download {
        width: 100%;
        display: flex;

        flex-direction: row;

        a {
          width: 100%;

          img {
            // height: auto;
            top: 0;
            width: 50%;

            // margin-right: 20px;
          }
        }
      }
    }
  }

  .control-dots {
    max-width: 19%;
    top: 87%;
    z-index: 3;

    .dot {
      margin: 3px !important;
      width: 11px !important;
      height: 12px !important;
    }
  }
}

@media only screen and (max-width: 410px) {
  .Logo_app_download {
    width: 100%;
    display: flex;

    flex-direction: row;

    a {
      width: 100%;

      margin-bottom: 40px;

      img {
        // height: auto;
        top: 0;
        width: 50%;
        // margin-right: 20px;
      }
    }
  }

  .carousel {
    height: 150px;

    .slide {
      .slider-image {
        height: 150px;
      }

      .slider-text {
        top: 19%;
        font-size: 9px;
      }

      .svg {
        img {
          height: 105%;
          top: 0%;
        }
      }

      .slider-logo {
        z-index: 3;
        position: absolute;
        max-width: 8%;
      }
    }

    .control-dots {
      top: 78%;
      left: -1%;

      .dot {
        margin: 2px !important;
        width: 7px !important;
        height: 7px !important;
      }
    }
  }
}

@media only screen and (min-width: 410px) {
  .carousel {
    height: 150px;

    .slide {
      .slider-image {
        height: 150px;
      }

      .slider-text {
        top: 32%;
        font-size: 10px;
      }

      .svg {
        height: 111%;
        top: 0%;
      }

      .slider-logo {
        z-index: 3;
        position: absolute;
        max-width: 8%;
      }
    }

    .control-dots {
      top: 78%;
      left: -2%;

      .dot {
        margin: 2px !important;
        width: 7px !important;
        height: 7px !important;
      }
    }
  }
}

@media only screen and (min-width: 450px) {
  .carousel {
    height: 188px;

    .slide {
      .slider-image {
        height: 188px;
      }

      .slider-text {
        top: 32%;
        font-size: 10px;
      }

      .svg {
        height: 116%;
        top: -5%;
      }
    }

    .control-dots {
      top: 70%;
      left: -2%;

      .dot {
        margin: 2px !important;
        width: 8px !important;
        height: 8px !important;
      }
    }
  }
}

@media only screen and (min-width: 560px) {
  .carousel {
    height: 227px;

    .slide {
      .slider-image {
        height: 227px;
      }

      .slider-text {
        top: 35%;
        font-size: 13px;
      }

      .svg {
        height: 104%;
        top: 0%;
      }

      .slider-logo {
        z-index: 3;
        position: absolute;
        max-width: 8%;
        top: 13%;
      }
    }

    .control-dots {
      top: 70%;
      left: -2%;

      .dot {
        margin: 2px !important;
        width: 8px !important;
        height: 8px !important;
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .carousel {
    height: 250px;

    .slide {
      .slider-image {
        height: 250px;
      }

      .slider-text {
        top: 35%;
        font-size: 13px;
      }

      .svg {
        height: 112%;
        top: 0%;
      }
    }

    .control-dots {
      top: 70%;
      left: 2%;
    }
  }
}

@media only screen and (min-width: 720px) {
  .carousel {
    width: 100%;
    height: 306px;

    .slide {
      .slider-image {
        height: 306px;
        top: -1%;
      }

      .slider-text {
        top: 30%;
        font-size: 16px;
      }

      .svg {
        height: 335px;
        top: -5%;
      }
    }

    .control-dots {
      left: 2%;
      top: 83%;
      z-index: 3;
    }
  }
}

@media only screen and (min-width: 820px) {
  .carousel {
    width: 100%;
    height: 328px;

    .slide {
      .slider-image {
        height: 328px;
        top: -1%;
      }

      .slider-text {
        top: 30%;
        font-size: 22px;
      }

      .svg {
        height: 382px;
        top: -5%;
      }
    }

    .control-dots {
      left: 3%;
      top: 83%;
      z-index: 3;
    }
  }
}

@media only screen and (min-width: 980px) {
  .carousel {
    width: 100%;
    height: 410px;

    .slide {
      .slider-image {
        width: 50%;
        height: 410px;
      }

      .svg {
        z-index: 2;
        height: 107%;
        top: 0%;
      }

      .slider-logo {
        z-index: 3;
        position: absolute;
        max-width: 4%;
        top: 17%;
      }

      .slider-text {
        font-size: 27px;
      }
    }

    .control-dots {
      left: 0;
      top: 83%;
      z-index: 3;

      .dot {
        margin: 3px !important;
        width: 16px !important;
        height: 16px !important;
      }
    }
  }
}

@media only screen and (min-width: 1100px) {
  .carousel {
    height: 442px;

    .slide {
      .slider-image {
        width: 50%;
        height: 442px;
      }

      .svg {
        z-index: 2;
        height: 109%;
        top: 0%;
      }

      .slider-logo {
        z-index: 3;
        position: absolute;
        max-width: 5%;
        top: 13%;
      }

      .slider-text {
        font-size: 32px;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .carousel {
    height: 470px;

    .slide {
      .slider-image {
        width: 50%;
        height: 470px;
      }

      .svg {
        z-index: 2;
        height: 107%;
        top: 0%;
      }

      .slider-logo {
        z-index: 3;
        position: absolute;
        max-width: 5%;
        top: 13%;
      }

      .slider-text {
        font-size: 32px;
      }
    }

    .control-dots {
      left: 0%;
      top: 92%;

      .dot {
        margin: 3px !important;
        width: 12px !important;
        height: 12px !important;
      }
    }
  }
}

@media only screen and (min-width: 1270px) {
  .carousel {
    height: 470px;

    .slide {
      .slider-image {
        width: 50%;
        height: 470px;
      }

      .svg {
        z-index: 2;
        height: 129%;
        top: -12%;
      }

      .slider-logo {
        z-index: 3;
        position: absolute;
        max-width: 5%;
        top: 13%;
      }

      .slider-text {
        font-size: 32px;
        width: 31%;
      }
    }
  }

  .control-dots {
    left: 0%;
    top: 79%;

    .dot {
      margin: 3px !important;
      width: 12px !important;
      height: 12px !important;
    }
  }
}

@media only screen and (min-width: 1600px) {
  .carousel {
    height: 542px;

    .slide {
      .slider-image {
        width: 50%;
        height: 542px;
      }

      .svg {
        z-index: 2;
        height: 127%;
        top: -14%;
      }

      .slider-logo {
        z-index: 3;
        position: absolute;
        max-width: 5%;
        top: 13%;
      }

      .slider-text {
        font-size: 32px;
      }
    }

    .control-dots {
      left: 5%;
      top: 79%;

      .dot {
        margin: 3px !important;
        width: 17px !important;
        height: 17px !important;
      }
    }
  }
}

@media only screen and (min-width: 1790px) {
  .carousel {
    height: 700px;

    .slide {
      .slider-image {
        width: 50%;
        height: 700px;
      }

      .svg {
        z-index: 2;
        height: 112%;
        top: -4%;
      }

      .slider-logo {
        z-index: 3;
        position: absolute;
        max-width: 5%;
        top: 10%;
      }

      .slider-text {
        font-size: 38px;
        width: 36%;
      }
    }

    .control-dots {
      left: 10%;
      top: 79%;

      .dot {
        margin: 3px !important;
        width: 17px !important;
        height: 17px !important;
      }
    }
  }
}

@media only screen and (min-width: 2000px) {
  .carousel {
    height: 700px;

    .slide {
      .slider-image {
        width: 50%;
        height: 700px;
      }

      .svg {
        z-index: 2;
        height: 127%;
        top: -12%;
      }
    }
  }
}

@media only screen and (min-width: 2100px) {
  .carousel {
    height: 750px;

    .slide {
      .slider-image {
        width: 50%;
        height: 750px;
      }

      .svg {
        z-index: 2;
        height: 117%;
        top: -12%;
      }

      .slider-logo {
        z-index: 3;
        position: absolute;
        max-width: 5%;
        top: 10%;
      }

      .slider-text {
        font-size: 38px;
        width: 40%;
      }
    }

    .control-dots {
      left: 15%;
      top: 79%;

      .dot {
        margin: 3px !important;
        width: 22px !important;
        height: 22px !important;
      }
    }
  }
}

@media only screen and (min-width: 2300px) {
  .carousel {
    height: 750px;

    .slide {
      .slider-image {
        width: 50%;
        height: 750px;
      }

      .svg {
        z-index: 2;
        height: 127%;
        top: -12%;
      }
    }
  }
}

@media only screen and (min-width: 2500px) {
  .carousel {
    height: 750px;

    .slide {
      .slider-image {
        width: 50%;
        height: 750px;
      }

      .svg {
        z-index: 2;
        height: 137%;
        top: -12%;
      }

      .slider-text {
        font-size: 41px;
        width: 25%;
      }
    }

    .control-dots {
      left: 18%;
      top: 79%;
    }
  }
}

@media only screen and (min-width: 2700px) {
  .carousel {
    height: 850px;

    .slide {
      .slider-image {
        width: 50%;
        height: 850px;
      }

      .svg {
        z-index: 2;
        height: 134%;
        top: -12%;
      }

      .slider-text {
        font-size: 41px;
        width: 25%;
      }
    }

    .control-dots {
      left: 20%;
      top: 79%;
    }
  }
}

@media only screen and (min-width: 2990px) {
  .carousel {
    height: 950px;

    .slide {
      .slider-image {
        width: 50%;
        height: 950px;
      }

      .svg {
        z-index: 2;
        height: 131%;
        top: -12%;
      }

      .slider-text {
        font-size: 41px;
        width: 20%;
      }
    }

    .control-dots {
      left: 21%;
      top: 79%;
    }
  }
}

@media only screen and (min-width: 5490px) {
  .carousel {
    height: 1950px;

    .slide {
      .slider-image {
        width: 50%;
        height: 1950px;
      }

      .svg {
        z-index: 2;
        height: 131%;
        top: -1%;
      }

      .slider-text {
        font-size: 93px;
        width: 20%;
      }
    }

    .control-dots {
      left: 31%;
      top: 79%;

      .dot {
        margin: 11px !important;
        width: 40px !important;
        height: 40px !important;
      }
    }
  }
}