.card-border {
  height: 100%;

  &:hover {
    box-shadow: 8px 3px 5px #acbece;
    transition: 0.8s;
  }
  h5 {
    color: #225e91;
  }
}
