.ikona {
    position: fixed;
    bottom: 30px;
    right: 30px;
    cursor: pointer;

    .ikonatt {
        display: block;
        bottom: 70px;
        position: fixed;
        margin-right: 17px;

        .shareikona {
            margin-top: 5px;
        }
    }

    .iconStyle {

        .mainIcon {
            padding: 7px 10px;
            background: #f84d0e;
            color: white;
            margin-right: 13px;
        }

        :hover {
            background: #ea693e;
        }
    }
}