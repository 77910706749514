.title {
  position: sticky;
  top: 0;
  background-color: white;
}
.chat {
  height: 450px;
  border: solid #a6c9e7 4px;
  // border-right: none;
  .user {
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
    font-size: 16px;
    width: max-content;
    background-color: #4194dd;
    color: white;
    border-radius: 30px;
    padding: 16px 33px;
    margin-bottom: 15px;
    margin-left: auto;
    word-wrap: break-word;
    span {
      max-width: 300px;
    }
  }
  .admin {
    display: flex;
    justify-content: start;
    width: max-content;
    background-color: white;
    border-radius: 30px;
    padding: 16px 33px;
    color: #4194dd;
    transition: 0.3s ease-in;
    margin-bottom: 20px;
    margin-left: 50px;
    word-wrap: break-word;
  }
}
.form-chat {
  position: sticky;
  bottom: 0;
  padding: 0 !important;
}
