.reportTitle {
  color: #225e91;
}
.continueBtn {
  background-color: #225e91;
  color: white;
  border: #225e91 3px solid;
}
.continueBtn:hover {
  background-color: white;
  color: #225e91;
  border: #225e91 3px solid;
}
.file {
  position: relative;
  height: 30px;
  width: 300px;
}
.file > input[type="file"] {
  position: absoulte;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.file > label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: white;
  color: #fff;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
}
.LoginBtn {
  padding: 10px;
  border-radius: 10px;
  background-color: #225e91;
  border: none;
  color: white;
}
.dateID {
  border: none;
  background-color: #d4e7f8;
  padding: 15px;
  border-bottom: 5px solid #225e91;
}

.disable {
  border-bottom: none;
}
.choose {
  background-color: #d4e7f8;
  color: black;
  border-bottom: 5px solid #225e91;

  margin-right: 50px;
}
.divpalidhje {
  padding-top: 20px;
  padding-bottom: 20px;
  .loginDataRegister {
    // width: 50%;
    // display: flex;
    // justify-content: space-around;
    margin-bottom: 40px;
    .registerOrLogin {
      padding: 0px !important;
    }
    .lang-btn-active {
      text-decoration: unset;
      color: black;
      padding: 5px 10px;
      border-bottom: #225e91 4px solid;
      // margin-right: 10px;
      background-color: #d4e7f8;
      cursor: pointer;
      text-align: center;
    }
    .lang-btn {
      text-decoration: unset;
      color: black;
      padding: 5px 10px;
      border-bottom: #d4e7f8 4px solid;
      background-color: white;
      // margin-right: 10px;
      cursor: pointer;
      text-align: center;
    }
    .buttonLogiR {
      background-color: #225e91;
      padding: 10px;
      text-align: center;
      color: #fff;
      font-weight: 600;
      border: 2px solid #fff;

      &:hover {
        background-color: #fff;
        color: #225e91;
        font-weight: 600;
        padding: 10px;
        border: 2px solid #225e91;
      }
    }
  }
}
