body {
  word-wrap: break-word;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.paginationBttns {
  margin-top: 20px;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
}

.paginationBttns a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid #225e91;
  color: #225e91;
  cursor: pointer;
}

.paginationBttns a :hover {
  color: white !important;
  background-color: #225e91;
}

.paginationActive a {
  color: white !important;
  background-color: #225e91;
}

.paginationDisabled a {
  display: none;
}
.aboutUsLaw {
  padding: 20px;
  width: 84%;
  /* font-weight: 600; */
  color: black;
  margin: 0 auto;
}
.linkpageAbout {
  border-left: 4px solid #225e91;
  border-right: 4px solid #225e91;
  /* margin-top: 20px; */
  /* margin-bottom: 20px; */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
