.single_news {
    word-wrap: break-word;

}

.allIcons {
    display: flex;
    justify-content: end;
    margin: 30px;

    .smallIcons {
        padding: 5px;
    }
}

@media only screen and (max-width: 600px) {
    .allIcons {
        justify-content: center !important;

    }
}