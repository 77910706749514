.banner_footer {
  background-color: #225e91;
  height: auto;
  // padding-top: 80px;
  padding: 80px 0px;

  .contact {
    font-weight: 600;
  }

  .contactinfo {
    display: flex;
    flex-direction: column;

    span {
      padding-bottom: 20px;
      font-weight: 100;
    }

    :last-child {
      padding-bottom: 0;
      margin-top: 20px;
    }
  }

  .aboutcompany {
    display: flex;
    flex-direction: column;

    a {
      color: white;
      text-decoration: none;
      padding-bottom: 10px;
      font-weight: 400;
    }

    :last-child {
      padding-bottom: 0;
    }
  }

  .report {
    font-weight: 100;

    a {
      text-decoration: none;
      color: white;
    }

    .Logo_app_download {
      width: 57%;
      img {
        width: 150%;
        cursor: pointer;
 
      }
    }
  }
}

.terms {
  color: white;
  text-decoration: none;
  padding-bottom: 10px;
  font-weight: 100;
}

.emailfooter {
  padding-top: 111px;
}

.policy {
  color: white;
  text-decoration: none;
  padding-bottom: 10px;
  font-weight: 100;
}

@media only screen and (max-width: 756px) {
  .contact {
    font-weight: 600;
    margin-bottom: 40px;

    p {
      padding-bottom: 10px !important;
      margin-top: 30px;
    }
  }

  .contact p:first-child {
    margin-bottom: -20px;
  }

  .contactinfo {
    margin-top: 30px;

    span {
      padding-bottom: 10px;
      margin-left: 20px;
      font-weight: 100;
    }

    :last-child {
      padding-bottom: 0;
    }
  }

  .text-footer-mobile {
    text-align: center;

  }

  .emailfooter {
    padding-top: 136px;
  }




  .report {
    font-weight: 100;

    a {
      text-decoration: none;
      color: white;
    }

    .Logo_app_download {
      width: 100%;
      margin: 0 auto;

      img {
        display: flex;
        flex-direction: column;
        height: auto;
        margin-bottom: -60px;
        margin-left: 32px;
        padding-bottom: 62px;
        max-width: 70%;
      }
    }
  }
}

@media only screen and (max-width: 321px) {
  .emailfooter {
    padding-top: 185px;
  }
}

@media only screen and (max-width: 376px) {
  .emailfooter {
    padding-top: 185px;
  }
}