.events {
  background: linear-gradient(#dae6fc, #4c8bc2);

  .text-upper {
    h1 {
      color: #225e91;
      font-weight: 700;
    }
    p {
      margin-top: 40px;
      font-size: 20px;
      color: black;
    }
  }
  .card-events {
    margin-top: -20px;
  }

  .bg-img {
    margin-top: 40px;
    max-width: 100%;
    max-height: 400px;
    min-height: 400px;

    .text-lower {
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: end;
      height: 100%;
      color: white;
      padding-left: 20px;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      a {
        text-decoration: none;
        color: white;
      }

      .date {
        margin-bottom: -4px;
      }

      .event-desc {
        color: #cecece;
        word-wrap: break-word;
      }
    }
  }
}
.btn-events {
  a {
    background-color: #225e91;
    color: white;
    margin: 40px 0;
    width: 150px;
    border-radius: 5px;
  }
  a:hover {
    color: white;
  }
}

@media only screen and (max-width: 600px) {
  .bg-img {
    max-height: 250px !important;
    min-height: 250px !important;
  }
  .text-lower {
    max-height: 250px;
    min-height: 250px;
  }
}
