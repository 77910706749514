.home {
  a {
    background-color: #225e91;
    color: white;
    margin: 40px 0;
    width: 150px;
    border-radius: 5px;
  }
  a:hover {
    background-color: #225e91;
    color: white;
  }
}
