.new-bgcolor {
  // background: linear-gradient(#e8eff5, #dae6fc);
  background: linear-gradient(#dae6fc, #4c8bc2);

  .container {
    h2 {
      color: #225e91;
      text-align: center;
      padding: 40px 0;
      text-transform: uppercase;
      font-weight: 700;
    }

    p {
      color: #cecece;
    }

    .res-img {
      .border-img {
        border-radius: 10px;
      }

      img {
        max-height: 350px;
        min-height: 350px;
        object-fit: cover;
        object-position: center;
        border-radius: 10px 10px 0 0;
      }

      .text-single {

        a {
          color: black;
          text-decoration: none !important;
        }
      }
    }

    .btn-news {
      a {
        background-color: #225e91;
        color: white;
        margin: 40px 0;
        width: 150px;
        border-radius: 5px;
      }
    }
  }

  .card-height {
    height: 100%;
  }
}

// .card-text {
//   height: 300px;
// }

.facebook_icon {
  display: flex;
  justify-content: end;
  align-items: flex-end;
}

@media only screen and (max-width: 600px) {
  .border-img img {
    min-height: 180px !important;
    max-height: 180px !important;
  }
}