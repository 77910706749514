.manualet {
  .title {
    color: #225e91;
  }
  h5 {
    height: 100px;
  }
  img {
    // max-height: 500px;
    // min-height: 500px;
    height: 400px;
    min-width: 100%;
    object-fit: contain;
  }
}
