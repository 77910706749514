.header1 {
  top: 0;
  right: 0;
  left: 0;
  position: sticky;
  z-index: 9999999;
}

.header-part1 {
  background-color: #ececec;
  text-align: center;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4;
}

.part1 {
  justify-content: end;
  display: flex;
  padding: 4px;

  .language {
    display: inline-block;
    position: relative;

    select {
      border-color: transparent;
      outline: 0;
      background: transparent;
      background-image: none;
      width: 100%;
      height: 100%;
      color: blue;

      cursor: pointer;

      option {
        width: 15em;
        height: 2em;
        line-height: 3;
        overflow: hidden;
        border-radius: 0.25em;
        padding-bottom: 10px;
      }
    }
  }

  a:not(.language) {
    border-right: 2px solid;
    padding-right: 10px;
    margin: 10px;
  }

  a {
    text-decoration: none;
  }

  .dropdown {
    display: inline-block;
    border: none;
    color: blue;
    background-color: #ececec;
  }

  .dropdown-menu {
    background-color: #ececec;
  }
}

.header-part2 {
  background-color: white;
}

.part2 {
  justify-content: space-between;
  display: flex;
  align-items: center;
  height: 56px;

  .main-button {
    // margin: 10px;
    background-color: #f84d0e;
    color: white;
  }
}

.rks {
  img {
    // margin-right: 80px;
    max-width: 256px;
  }
}

.logo {
  img {
    // margin-right: 80px;
    max-width: 256px;
  }
}

.header-part3 {
  background: linear-gradient(#458dcb, #063761);
  padding-bottom: 5px;
}

.part3 {
  // display: -webkit-inline-box;
  // display: inline-block;
  display: flex;
  justify-content: center;

  li {
    margin: 7px;
    margin-top: 14px;
    list-style-type: none;

    a {
      text-decoration: none;
      color: white;
    }
  }

  .dropdownLaws {
    margin-top: 5px;
  }
}

.active a {
  // border-bottom: rgba(116, 181, 201, 0.733) 2px solid;
  border-bottom: rgb(118, 155, 187) 6px solid;
  // border-radius: 3px;
}

.mobileMenu {
  // position: fixed;
  display: none;
  left: 0;
  right: 0;
  top: 10;
  height: 50px;
  width: 100%;
  background: linear-gradient(#458dcb, #063761);
  padding-top: 3px;
  z-index: 999999;
  // margin-top: 114px;

  button {
    font-size: 28px;
    border: none;
    background-color: transparent;
    color: white;
    margin-bottom: 3px;
    font-weight: 800;
  }
}

.mobileMenuContent {
  top: 20%;
  left: 0;
  right: 0;
  z-index: 999999;
  display: none;
  margin-bottom: -16px;
  /* bottom: 0; */
  height: auto;
  width: 100%;
  background: linear-gradient(#458dcb, #063761);

  ul {
    // border-top: 3px solid #bbb;
    list-style-type: none;
    cursor: pointer;

    li {
      padding: 8px;
      margin-left: 19px;
      text-decoration: none;
      display: flex;
      flex-direction: column;

      a {
        padding: 5px;
        border-radius: 26%;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
  }
}

.modal-content {
  border-radius: 0% !important;

  .modal-body {

    .btn,
    .form-select {
      border-radius: 0% !important;
    }

    .upload-images {
      border: #0d6efd 2px solid;
    }

    .languages {
      p {
        margin: 10px;
        padding: 5px;
        cursor: pointer;
      }
    }
  }
}

.dropbtn {
  background: transparent;
  color: white;
  margin-bottom: 10px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  left: 0;
  display: none;
  position: absolute;
  right: 0;
  background: linear-gradient(#3b80ba, #063761);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background: transparent;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background: linear-gradient(#458dcb, #063761);
}

/* Style the active class, and buttons on mouse-over */
@media only screen and (max-width: 1024px) {
  .header-part2 {
    background-color: white;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .part2 {
    .logo {
      img {
        width: 94%;
      }
    }

    .main-button {
      // margin: 10px;
      background-color: #f84d0e;
      color: white;
      width: 80px;
      height: 40px;
    }
  }

  .rks {
    img {
      width: 94%;
      // margin-right: 80px;
    }
  }

  .part3 {
    li {
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .header-part2 {
    .main-button {
      font-size: 10px !important
    }
  }
}

@media only screen and (max-width: 800px) {
  .header {
    position: unset;
  }


  .part1 {
    width: fit-content;
    align-items: center;
    font-size: 9px;
    margin: 0 auto;

    .link {
      a {
        border-right: none;
        padding-right: 2px;
      }
    }
  }

  .part2 {
    height: 80px;
  }

  .part3 {
    display: none;
  }

  .mobileMenu {
    display: table;
  }

  .header-part3 {
    display: none;
  }

  .mobileMenuContent {
    top: 20%;
    left: 0;
    right: 0;
    z-index: 999999;
    display: block;
    margin-bottom: -16px;
    height: auto;
    width: 100%;
    background: linear-gradient(#458dcb, #063761);
  }
}

.drop_background {
  background: linear-gradient(#458dcb, #063761);
}