.profile {
  background-color: #f2f3f5;
  .profile_layout {
    display: flex;
    flex-direction: row;
    .profile_layout_structure {
      width: 50%;
      margin-left: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      .info_profile {
        background-color: white;
        box-shadow: 8px 3px 5px #acbece;
        // border-radius: 10%;
        // padding: 60px;
        width: 80%;
        height: 600px;

        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        button {
          width: 50%;
          height: 50px;
          text-align: center;
          display: flex;
          flex-direction: column;
          margin-top: 20px;
          border: none;
          background-color: #225e91;
          color: white;
          border-radius: 20px;
          &:hover {
            background-color: white;
            border: 3px solid #225e91;
            color: #225e91;
          }
          p {
            margin-top: 10px;
            font-weight: 600;
            width: 100%;
          }
        }
      }
    }
    h3 {
      color: #225e91;
      margin-right: 160px;
      margin-top: 40px;
    }

    .boxes {
      width: 50%;
      margin-top: 10px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      word-wrap: break-word;
      .boxes_layout {
        width: 50%;
        margin: 20px;
        margin-left: 160px;
        box-shadow: 8px 3px 5px #acbece;
        h5 {
          color: #225e91;
        }
      }
    }
    x input {
      border: none;
      background-color: #e4e8e9;
      padding: 12px;
    }

    .button {
      width: 50%;
      max-height: 60px;
      margin-left: 160px;
      margin-bottom: 10px;
      font-weight: 600;
      color: #225e91;
      border: 4px solid #225e91;
      border-radius: 60px;
      &:hover {
        background-color: #225e91;
        p {
          color: white;
        }
      }

      p {
        margin-top: 12px;
        color: #225e91;
      }
    }
  }
  .btnSave {
    background-color: #225e91;
    color: white;
  }
  .modal {
    z-index: 999999999999;
    margin-top: 100px;
  }
}

@media only screen and (max-width: 600px) {
  .profile {
    background-color: #f2f3f5;
    .profile_layout {
      display: flex;
      flex-direction: column;
      width: 100%;
      .profile_layout_structure {
        width: 90%;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        margin-top: 40px;
        .info_profile {
          box-shadow: 8px 3px 5px #acbece;
          border-radius: 10%;
          padding: 20px;
          width: 80%;
          margin: 0 auto;
          height: auto;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          button {
            width: 100%;
            height: 50px;
            text-align: center;
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            border: none;
            background-color: #225e91;
            color: white;
            border-radius: 20px;

            p {
              margin-top: 10px;
              font-weight: 600;
              width: 100%;
            }
          }
        }
      }
      .modal {
        margin-top: 250px;
      }
      .boxes {
        width: 100%;
        margin-top: 10px;
        h3{
          width: 100%;
          margin-left: -40px;

        }
        .boxes_layout {
          background-color: white;
          margin: 0 auto;
          margin-bottom: 40px;
          width: 80%;
          padding: 20px;
          box-shadow: 8px 3px 5px #acbece;
        }
      }
      x input {
        border: none;
        background-color: #e4e8e9;
        padding: 12px;
      }

      h3 {
        margin-left: 30px;
      }
      .button {
        width: 60%;
        max-height: 60px;
        margin-left: 80px;
        margin-bottom: 30px;
        font-weight: 600;
        color: #225e91;
        border: 4px solid #225e91;
        border-radius: 60px;

        p {
          margin-top: 12px;
        }
      }
    }
    .modal {
      z-index: 99999;
      margin-top: 20px;
    }
  }
}
