.loginform {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px;
  .loginform_layout {
    width: 30%;
    padding: 10px 60px;
    padding-bottom: 40px;
    box-shadow: 0 0 25px rgb(177, 177, 177);
    p {
      margin-top: 20px;
    }
    a {
      text-decoration: none;
      color: #1579d1;
      margin-left: 10px;
    }
  }
}
.submit_button {
  padding: 10px;
  border-radius: 10px;
  background-color: #225e91;
  border: none;
  color: white;
}

@media only screen and (max-width: 600px) {
  .loginform {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 10px 80px 10px;
    .loginform_layout {
      width: 100%;
      padding: 40px 20px;
      box-shadow: 0 0 25px gray;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
