h1 {
  margin-top: 40px;

  color: #225e91;
  margin-bottom: 25px;
}
.about_us_info {
  width: 70%;
  margin: 0 auto;
  color: #bbbbbb;
  margin-bottom: 45px;
  .btn-about {
    a {
      background-color: #225e91;
      color: white;
      margin: 40px 0;
      width: 150px;
      border-radius: 5px;
    }
    a:hover {
      color: white;
    }
  }
}

@media only screen and (max-width: 600px) {
  .about_us_info {
    width: 100%;
    color: #bbbbbb;
    margin-bottom: 45px;
  }
}
