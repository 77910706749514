
   
    
        .border-img{

            border-radius: 10px;

            img{
                max-height: 350px;
                min-height: 350px;
                object-fit: cover;
                object-position: center;
                border-radius: 10px 10px 0 0 ;
            }
        }
       
        .text-single{
           
            a{
                color: black;
                text-decoration: none!important;
            }
        }
    
        .btn-news {
            a{
                background-color: #0C6ABC;
                color: white;
                margin: 40px 0;
                width: 150px;
                border-radius: 5px;
            }
        }
    
    .card-height{
        height: 100%;
    }
    
    
    @media only screen and (max-width: 600px) {
        .border-img img{
                
                min-height: 180px!important;
                max-height: 180px!important;
          
        }
      }
    