$firstFontSize: calc(100vw / 23);
$secondFontSize: calc(100vw / 32);

a,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}

.foto-links {
  background-image: url("./Images/LinksPhoto.png");
  background-size: cover;
  background-position: center center;
  height: 90%;
  width: 100%;
  color: white;
  word-wrap: normal;
  .links {
    // font-size: 20px;
    // height: 100%;
    display: flex;
    flex-wrap: row wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 15;

    .link {
      transition: 1.5s color ease;
    }

    .first-row {
      height: 70px;
      margin: 1px;
    }

    .second-row {
      height: 80px;
    }

    .third-row {
      height: 80px;
    }

    .fourth-row {
      height: 50px;
    }
  }

  .active {
    color: red;
  }

  .Protection {
    font-size: $firstFontSize;
    font-weight: 600;
    text-align: right;
    // margin: 5px;
  }

  .Laws {
    font-size: $secondFontSize;
    font-weight: 400;
    margin: 10px 0;
    // margin-top: 15px;
  }

  .Report {
    font-size: $firstFontSize;
    margin-left: 40px;
    font-weight: 600;
  }

  .Discrimination {
    font-size: $secondFontSize;
    font-weight: 500;
    margin-top: 15px;
    text-align: right;
  }

  .Anti-Gypsism {
    font-size: $firstFontSize;
    font-weight: 600;
  }

  .Human {
    font-size: $firstFontSize;
    font-weight: 600;
    text-align: right;
  }

  .National {
    font-size: $secondFontSize;
    font-weight: 500;
    margin-top: 40px;
    text-align: center;
  }

  .Egyptian {
    font-size: $secondFontSize;
    font-weight: 500;
    margin-top: 20px;
    text-align: center;
  }

  .Kosovo {
    font-size: $firstFontSize;
    font-weight: 600;
    margin: 30px;
  }

  .Minorities {
    font-size: $firstFontSize;
    font-weight: 600;
    text-align: left;
  }

  .Ashkali {
    font-size: $secondFontSize;
    font-weight: 500;
    margin-top: 15px;
    text-align: left;
  }

  .Roma {
    font-size: $secondFontSize;
    font-weight: 500;
    margin-top: 10px;
  }

  .Awareness {
    text-align: right;
    font-size: $firstFontSize;
    font-weight: 600;
    padding-right: 10%;
  }
  .Prevention {
    text-align: right;
    font-size: $firstFontSize;
    padding-right: 7%;
  }
}

@media only screen and (max-width: 768px) {
  .links {
    width: 75% !important;
    // border: 3px solid red;
    justify-content: center;
    text-align: center;
  }

  // first, second, third, fourth rows, jon te emrume ne baze te rendit pa responsive
  .first-row {
    height: 0;
    margin: 0;
    .Protection {
      margin: 0;
      font-size: calc(100vw / 18);
    }
    .Laws {
      margin: 0;
      font-size: calc(100vw / 18);
    }
    .Report {
      margin: 0;
      font-size: calc(100vw / 18);
    }
  }

  .second-row {
    height: 0;
    .Discrimination {
      // margin: 0;
      font-size: calc(100vw / 22);
    }
    .Anti-Gypsism {
      margin: 0;
      font-size: calc(100vw / 22);
    }
  }

  .third-row {
    height: 0;
    .Human {
      font-size: calc(100vw / 22);
    }
    .National {
      margin: 0;
      font-size: calc(100vw / 18);
    }
    .Egyptian {
      margin: 0;
      font-size: calc(100vw / 18);
    }
    .Kosovo {
      margin: 0;
      font-size: calc(100vw / 18);
    }
  }

  .fourth-row {
    // height: none;
    .Minorities {
      // height: 80px;
      text-align: center;
      font-size: calc(100vw / 18);
    }
    .Ashkali {
      text-align: center;
      font-size: calc(100vw / 18);
    }
    .Roma {
      font-size: calc(100vw / 18);
    }
  }
  // .Awareness {
  //   font-size: calc(100vw / 15);
  //   text-align: center;
  // }
}
.fifth-row{
  margin-top: 35px;
}
