.howToUse{

    h1 {
    margin-top: 40px;
    color: #225e91;
    margin-bottom: 25px;
}
video{
    display: flex;
    justify-content: center;
    width: 70%;
    margin: 0 auto;
    border: 1px solid #c5c5c5;
}
}