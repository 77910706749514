.partneret {
  background: linear-gradient(#4c8bc2, #225e91);
  padding: 20px;
  // height: 500px;
  width: 100%;
  padding-bottom: 40px;
  .partneretIMG {
    width: 30%;
    img {
      margin-top: 40px;
      margin-bottom: 40px;
      max-width: 230px;
      min-width: 230px;
      // max-height: 70px;
      // min-height: 70px;
    }
    h2 {
      color: white;
    }
  }
}

@media only screen and (max-width: 600px) {
  .partneret {
    background: linear-gradient(#4c8bc2, #225e91);
    padding: 20px;
    // height: 500px;
    width: 100%;
    padding-bottom: 40px;
    .partneretIMG {
      width: 80%;
      margin: 0 auto;
      img {
        margin-top: 40px;
        margin-bottom: 40px;
        max-width: 180px;
        min-width: 180px;
        // max-height: 80px;
        // min-height: 80px;
      }
      h2 {
        color: white;
      }
    }
  }
}
