.signupForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px;
  .singupform_layout {
    width: 40%;
    padding: 10px 60px;
    padding-bottom: 60px;
    box-shadow: 0 0 25px #225e91;
  }
  .submit_button {
    padding: 10px;
    border-radius: 10px;
    background-color: #225e91;
    border: none;
    color: white;
  }
}
.l-radio {
  padding: 6px;
  border-radius: 50px;
  display: inline-flex;
  cursor: pointer;
  margin: 8px 0;
  -webkit-tap-highlight-color: transparent;
}
.l-radio:hover,
.l-radio:focus-within {
  background: #dbe1e6;
}
.l-radio input {
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: none;
  border: 0;
  box-shadow: inset 0 0 0 1px #225e91;
  box-shadow: inset 0 0 0 1.5px #225e91;
  appearance: none;
  padding: 0;
  margin: 0;
  transition: box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);
  pointer-events: none;
}
.l-radio input:focus {
  outline: none;
}
.l-radio input:checked {
  box-shadow: inset 0 0 0 6px #225e91;
}
.l-radio span {
  vertical-align: middle;
  display: inline-block;
  line-height: 20px;
  padding: 0 8px;
}
@media only screen and (max-width: 600px) {
  .signupForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 10px 80px 10px;
    .singupform_layout {
      width: 100%;
      padding: 40px 20px;
      box-shadow: 0 0 25px gray;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
